// src/utils/updateMetaTags.js
export const updateMetaTags = (kid) => {
    document.title = `${kid.name} - Profile`;

    const metaTags = [
        { property: 'og:title', content: `${kid.name} - Profile` },
        { property: 'og:description', content: `Check out ${kid.name}'s profile!` },
        { property: 'og:image', content: kid.photoUrl },
        { property: 'twitter:title', content: `${kid.name} - Profile` },
        { property: 'twitter:description', content: `Check out ${kid.name}'s profile!` },
        { property: 'twitter:image', content: kid.photoUrl },
    ];

    metaTags.forEach(({ property, content }) => {
        let element = document.querySelector(`meta[property='${property}']`) || document.createElement('meta');
        element.setAttribute('property', property);
        element.setAttribute('content', content);
        if (!element.parentNode) {
            document.head.appendChild(element);
        }
    });
};