// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import KidProfile from './KidProfile';
import KidProfileJSON from './KidProfileJSON';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/kid/:id" element={<KidProfile />} />
        <Route path="/kid/json/:id" element={<KidProfileJSON />} />
      </Routes>
    </Router>
  );
};

export default App;