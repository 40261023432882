// profileUtils.js

export const getFieldValue = (fieldSections, key) => {
    for (const section of fieldSections) {
        for (const field of section.fields) {
            if (field.key === key && field.hideValue !== true) {
                return field.value.value;
            }
        }
    }
    return null;
};

export const getFirstSectionFields = (fieldSections, excludeFields) => {
    if (fieldSections && fieldSections.length > 0) {
        return fieldSections[0].fields.filter(field =>
            !field.hideValue &&
            !excludeFields.includes(field.key) &&
            field.key !== 'Parents' &&
            field.value.value != null &&
            field.value.value !== '' &&
            (Array.isArray(field.value.value) ? field.value.value.length > 0 : true)
        );
    }
    return [];
};

export const isFieldValueHidden = (fieldSections, key) => {
    for (const section of fieldSections) {
        for (const field of section.fields) {
            if (field.key === key) {
                return field.hideValue === true;
            }
        }
    }
    return false;
};
