// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD8ZPeuPgv2E9dl6PAXCyA95tSDekVuEPw",
    authDomain: "supergenia-app.firebaseapp.com",
    projectId: "supergenia-app",
    storageBucket: "supergenia-app.appspot.com",
    messagingSenderId: "216471154241",
    appId: "1:216471154241:web:90c89900a18486f2b320c0",
    measurementId: "G-2E0XR69Q6R"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };