import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { getFieldValue, isFieldValueHidden, getFirstSectionFields } from './utils/profileUtils';
import { updateMetaTags } from './utils/updateMetaTags';
import { useParams } from 'react-router-dom';
import './KidProfile.css';

const KidProfile = () => {
    const { id } = useParams();
    const [kidProfile, setKidProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchKidProfile = async () => {
            try {
                const docRef = doc(db, "kidProfiles", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const profileData = docSnap.data();
                    setKidProfile(profileData);
                    const name = getFieldValue(profileData.fieldSections, 'Name');
                    const photoURL = getFieldValue(profileData.fieldSections, 'Photo');
                    updateMetaTags({ name, photoUrl: photoURL });
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchKidProfile();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!kidProfile) {
        return <div>No profile found</div>;
    }

    const getField = (fieldName) => {
        if (isFieldValueHidden(kidProfile.fieldSections, fieldName)) {
            return null;
        }
        const value = getFieldValue(kidProfile.fieldSections, fieldName);
        return value != null && value !== '' && (Array.isArray(value) ? value.length > 0 : true) ? value : null;
    };

    const name = getField('Name');
    const photoURL = getField('Photo');
    const city = getField('City');
    const country = getField('Country');
    const bio = getField('Bio');

    const explicitlyUsedFields = ['Name', 'Photo', 'City', 'Country', 'Bio'];
    const firstSectionFields = getFirstSectionFields(kidProfile.fieldSections, explicitlyUsedFields);

    return (
        <div className="profile-container">
            {photoURL && (
                <div className="profile-picture">
                    <img src={photoURL} alt={name || 'Profile'} />
                </div>
            )}
            <div className="profile-details">
                {name && <h2>{name}</h2>}
                {(city || country) && <p>{[city, country].filter(Boolean).join(', ')}</p>}
                {firstSectionFields.length > 0 && (
                    <section className="profile-section">
                        <h3>GENERAL</h3>
                        {firstSectionFields.map(field => (
                            <p key={field.key}>
                                <strong>{field.key}:</strong> {
                                    Array.isArray(field.value.value)
                                        ? field.value.value.join(', ')
                                        : field.value.value
                                }
                            </p>
                        ))}
                    </section>
                )}
                {bio && (
                    <section className="profile-section">
                        <h3>BIO</h3>
                        <p>{bio}</p>
                    </section>
                )}
            </div>
        </div>
    );
};

export default KidProfile;
